const config = {
  /* Site
    ========================================= */
  title: `PraneshASP's Blog`, // Displayed in header
  description: `Tutorials, research, learnings and notes`, // Site description
  maxWidth: "850px", // Max width of website
  enableAbout: false, // Enables about page
  siteUrl: `https://flawsomedev.com/`, // For sitemap & RSS feed
  useScrollIndicator: true, // Use scroll indicator on top of screen in posts

  /* Profile
    ========================================= */
  author: `PraneshASP`, // Name shows on profile
  profileDescription: "Tutorials, research, learnings and notes...", // Shows under author name
  profileImageName: `profile.jpg`, // Place profile.jpg/.jpeg/.png in _assets folder
  location: "On-chain", // Location under profileDescription. "" --> disabled
  footerLink: "https://praneshasp.com", // Link to page when you click footer name

  /* Social Media Links
        accountName & emailAddress: leave blank ("") to disable
        showHeaderIcon: shows social media icon in header. When true, must have account name set
    ========================================= */
  socialMediaLinks: {
    email: { emailAddress: "praneshas2000@gmail.com", showHeaderIcon: false },
    github: {
      accountName: "PraneshASP",
      showHeaderIcon: true,
    },
    website: { accountName: "/", showHeaderIcon: false },
    mirror: { accountName: "0xF314e9Cc3D5F382669eeB01d31f421aF931b9eBB", showHeaderIcon: false },
    twitter: { accountName: "0xasp_", showHeaderIcon: false },
    linkedIn: { accountName: "", showHeaderIcon: false }, // Use URL after "linkedin.com/"
    medium: { accountName: "pranesh-a-s", showHeaderIcon: false }, // Use URL after "medium.com/@"
  },

  /* Social Media Share Buttons--available below every post
    ========================================= */
  shareButtons: {
    email: false,
    facebook: true,
    twitter: true,
    reddit: true,
    linkedIn: true,
  },

  /* Comments
    ========================================= */
  comments: {
    facebook: { enabled: false, appId: "" }, // appId: optional, but used to moderate fb comments
    disqus: { enabled: false, shortName: "" }, // Check your shortName on https://disqus.com
    utterances: { enabled: false, repoUrl: "" }, // i.e. 'ellismin/gatsby-blog-mdx'
  },

  /* More Custom Settings
    ========================================= */
  defaultTheme: "dark", // Options: dark / light -- applied to first visitors
  showTimeToRead: true, // Shows time to read for each post in main page
  breakCodeLines: true, // Breaks long code lines instead of having horizontal scroll
  faviconSrc: `_assets/favicon.png`, // Favicon
  gaTrackingId: `UA-163503892-1`, // Your google analytics tracking id--i.e. UA-*****
  googleAdSenseId: ``, // Your google AdSense client id--i.e. ca-pub-****
}

module.exports = config
